




































































































































































import DeleteDialog from '@/components/admin/DeleteDialog.vue';
import EditVariableDialog from '@/components/admin/EditVariableDialog.vue';
import VariableDTO from '@/models/VariableDTO';
import FileService from '@/services/FileService';
import RemoteServices from '@/services/RemoteServices';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        EditVariableDialog,
        DeleteDialog,
    },
})
export default class Variables extends Vue {
    files: File[] = [];
    uploadProgress = 0;
    headers = [
        { text: 'Variable ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Theme', value: 'theme' },
        { text: 'Access Level', value: 'accessLevel' },
        { text: 'Data Type', value: 'type' },
        { text: 'Timeseries', value: 'timeseries' },
        { text: 'Multiple', value: 'multiple' },
        { text: 'Enum Values', value: 'enumValues' },
        { text: 'Units', value: 'units' },
        { text: 'Methodology', value: 'methodology' },
        { text: 'Actions', value: 'action' },
    ];

    themes = [
        'LOCATION',
        'GEOREFERENCE',
        'CULTURAL',
        'GEOMORPHOLOGY',
        'BIODIVERSITY',
        'DISTURBANCE',
        'TOURISM',
        'DIVING',
        'ORGANIZATION',
        'REGULATION',
        'WATER',
    ];
    dataTypes = [
        'TEXT',
        'BOOLEAN',
        'ENUM',
        'JSON',
        'UNITLESS_NUMBER',
        'NUMBER_WITH_UNITS',
        'DATETIME',
        'DATE',
        'TIME',
    ];
    accessLevels = ['PUBLIC', 'PRIVATE', 'SENSITIVE'];
    timeseries = [true, false];
    multiple = [true, false];

    search = '';
    newVariable = new VariableDTO();
    filterThemes: string[] = [];
    filterAccessLevels: string[] = [];
    filterTimeseries: boolean[] = [];
    filterDataTypes: string[] = [];
    filterMultiple: boolean[] = [];

    variables: VariableDTO[] = [];

    get filteredVariables(): VariableDTO[] {
        return this.variables
            .filter(
                (v) =>
                    !this.filterThemes.length ||
                    this.filterThemes.includes(v.theme),
            )
            .filter(
                (v) =>
                    !this.filterAccessLevels.length ||
                    this.filterAccessLevels.includes(v.accessLevel),
            )
            .filter(
                (v) =>
                    !this.filterTimeseries.length ||
                    this.filterTimeseries.includes(v.timeseries),
            )
            .filter(
                (v) =>
                    !this.filterMultiple.length ||
                    this.filterMultiple.includes(v.multiple),
            )
            .filter(
                (v) =>
                    !this.filterDataTypes.length ||
                    this.filterDataTypes.includes(v.type),
            );
    }

    async created(): Promise<void> {
        await this.$store.dispatch('loading');

        (async () => {
            let generator = RemoteServices.variablesGenerator(30);
            for await (let batch of generator) {
                if (!this.variables.length)
                    await this.$store.dispatch('clearLoading');

                this.variables.push(...batch);
            }
        })().catch(async (error) => {
            await this.$store.dispatch('error', error);
        });
    }

    async createVariable(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.createVariable(this.newVariable);
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
        this.newVariable = new VariableDTO();
    }

    async updateVariable(variable: VariableDTO): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.updateVariable(variable);
        } catch (error) {
            // TODO: revert to original value in case of failure
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    async deleteVariable(variable: VariableDTO): Promise<void> {
        await RemoteServices.deleteVariable(variable.id);
        this.variables = this.variables.filter((v) => v.id != variable.id);
    }

    async download(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            const csv = await RemoteServices.variablesToCsv();
            FileService.download(csv, 'variables.csv', 'text/csv');
        } catch (error) {
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }

    selectFiles(files: File[]): void {
        this.uploadProgress = 0;
        this.files = files;
    }

    async upload(): Promise<void> {
        await this.$store.dispatch('loading');

        try {
            await RemoteServices.csvToVariables(this.files, (event) => {
                this.uploadProgress = Math.round(
                    (100 * event.loaded) / event.total,
                );
            });
        } catch (error) {
            this.uploadProgress = 0;
            await this.$store.dispatch('error', error);
        }

        await this.$store.dispatch('clearLoading');
    }
}
